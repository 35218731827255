import abi from './assets/abi.jpg'
import logesh from './assets/logesh.jpg'
import sri from './assets/sri.jpg'
import ram from './assets/ramharrish.png'
import darsan from './assets/darsan.png'

const featuredTestimonial = {
  body: 'I am amazed at how quickly my child picked up English speaking skills. The teachers make learning so enjoyable!',
  author: {
    name: ' - Abinaya.R., Parent of a 8-Year-Old',
    imageUrl:abi
    // logoUrl: 'https://tailwindui.com/img/logos/savvycal-logo-gray-900.svg',
  },
}
const testimonials = [
  [
    [
      {
        body: 'The phonics section in the course made learning English so exciting for my son. He now enjoys reading English a lot. Within 3 months there was tremendous change in him.',
        author: {
          name: '- Sri Vidhya.S., Parent of 8 year old',
          // handle: 'lesliealexander',
          imageUrl:sri
                },
      },
      // More testimonials...
    ],
    [
      {
        body: 'The lessons are so well-structured and fun that my son doesn’t even realise how much he’s learning. His English fluency has elevated tremendously ',
        author: {
          name: '- Logesh.R.D., Parent of 13 year old',
          // handle: 'lindsaywalton',
          imageUrl:logesh
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'The personalized guidance and focused lessons made a huge difference in my IELTS preparation. The one-on-one sessions helped me improve my weak areas, and the mock tests gave me the confidence I needed. I’m so grateful for this amazing learning experience!',
        author: {
          name: '- Darsan.J., IELTS Aspirant ',
          // handle: 'tomcook',
          imageUrl:darsan     
         },
      },
      // More testimonials...
    ],
    [
      {
        body: 'I never imagined I could improve my English so quickly! The lessons were interactive, and the teachers made complex concepts easy to understand. I feel much more confident speaking and writing in English now.',
        author: {
          name: '-  Ram harrish.',
          handle: 'leonardkrasner',
          imageUrl:ram
        },
      },
      // More testimonials...
    ],
  ],
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Testmonialex() {
  return (
    <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[##3d9cd5] to-[#9089fc]"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#3d9cf5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Testimonials</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-500 sm:text-4xl">
            We have worked with many amazing Aspiring Learners
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg1 font-semibold leading-7 tracking-tight text-gray-500 sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <img
                alt=""
                src={featuredTestimonial.author.imageUrl}
                className="h-18 w-16 flex-none rounded-full bg-gray-50"
              />
              <div className="flex-auto">
                <div className="font-semibold">{featuredTestimonial.author.name}</div>
                {/* <div className="text-gray-600">{`@${featuredTestimonial.author.handle}`}</div> */}
              </div>
              {/* <img alt="" src={featuredTestimonial.author.logoUrl} className="h-10 w-auto flex-none" /> */}
            </figcaption>
          </figure>
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={classNames(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                      ? 'xl:row-span-2'
                      : 'xl:row-start-1',
                    'space-y-8',
                  )}
                >
                  {column.map((testimonial) => (
                    <figure
                      // key={testimonial.author.handle}
                      className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                      <blockquote className="text-gray-500 text-sm font-semibold">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img alt="" src={testimonial.author.imageUrl} className="h-16 w-16 rounded-full bg-gray-50" />
                        <div>
                          <div className="font-semibold text-sm">{testimonial.author.name}</div>
                          {/* <div className="text-gray-600">{`@${testimonial.author.handle}`}</div> */}
                        </div>
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
